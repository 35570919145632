<script lang="ts" setup>
import { useRoute } from 'vue-router';
import { type SeoData } from '~/data/entities';
import { useGlobalStore } from '~/stores/global';
import { calculatePricing } from '@laam/lib/utils';
import { useLoganStore } from '~/stores/logan';
import { productTitle } from '~/utils/helpers';

const { isHvc } = useLoganStore();

interface WebPageSeoProps {
	data?: Pick<
		SeoData,
		| 'title'
		| 'subtitle'
		| 'description'
		| 'meta_image'
		| 'meta_description'
		| 'meta_title'
	>;
	itemListData?: any;
	handle?: string;
	count?: number;
}

const props = defineProps<WebPageSeoProps>();
const { data, itemListData } = toRefs(props);
const route = useRoute();
const { storeType } = useGlobalStore();

const stockStatus = (product: any) => {
	return product.total_inventory_quantity > 0 ? 'InStock' : 'OutOfStock';
};

const price = (product: any) => {
	const displayPrice = calculatePricing(product.variants, isHvc);
	return displayPrice?.discountedPrice
		? displayPrice?.discountedPrice
		: displayPrice?.originalPrice;
};

const script = computed(() => {
	const env = process.server
		? process.env.NUXT_PUBLIC_ENV
		: useRuntimeConfig().public.env;
	if (env !== 'production' || !itemListData.value || storeType === 'OCTANE')
		return [];
	return [
		{
			type: 'application/ld+json',
			children: JSON.stringify({
				'@context': 'https://schema.org',
				'@type': 'WebPage',
				'@id': `https://laam.pk/${route.fullPath}`,
				name:
					data.value?.meta_title !== ''
						? data.value?.meta_title
						: data.value?.title,
				url: `https://laam.pk/${route.fullPath}`,
				description:
					data.value?.meta_description !== ''
						? data.value?.meta_description
						: data.value?.description
							? data.value?.description
							: // eslint-disable-next-line quotes
								"LAAM.pk is Pakistan's one-stop fashion online store that is home to the country's finest designer wear. The main aim behind this venture is to host various Pakistani designer, prêt, lifestyle and luxury brands under one roof where global customers can shop what they want to under the banner of LAAM.",
				image: {
					'@type': 'ImageObject',
					url: route.fullPath.includes('collections')
						? itemListData.value[0].products[0].image.src
						: data.value?.meta_image,
				},
				publisher: {
					'@type': 'Organization',
					name: 'Laam',
					logo: {
						'@type': 'ImageObject',
						url: 'https://cdn.shopify.com/s/files/1/2337/7003/files/laam_logo_svg.svg?v=1738576396',
					},
				},
			}),
		},
		{
			type: 'application/ld+json',
			children: JSON.stringify({
				'@context': 'https://schema.org',
				'@type': 'ItemList',
				name:
					data.value?.meta_title !== ''
						? data.value?.meta_title
						: data.value?.title,
				url: `https://laam.pk/${route.fullPath}`,
				numberOfItems: props.count,
				itemListElement: itemListData.value[0]?.products.map(
					(product: any, index: number) => ({
						'@type': 'ListItem',
						position: index + 1,
						name: product.title,
						url: `https://laam.pk/products/${product.handle}`,
						image: product.image?.src,
					}),
				),
			}),
		},
		{
			type: 'application/ld+json',
			children: JSON.stringify(
				(itemListData.value[0]?.products || []).map((product: any) => ({
					'@context': 'https://schema.org',
					'@type': 'Product',
					name: productTitle(product, true).title,
					image: product.media?.[0]?.src,
					brand: {
						'@type': 'Brand',
						name: product.brand || 'Unknown Brand',
					},
					offers: {
						'@type': 'Offer',
						url: `https://laam.pk/products/${product.handle}`,
						priceCurrency: 'PKR',
						price: price(product),
						itemCondition: 'https://schema.org/NewCondition',
						availability: `https://schema.org/${stockStatus(product)}`,
						seller: {
							'@type': 'Organization',
							name: 'LAAM',
						},
					},
				})),
			),
		},
	];
});

useHead({
	script,
});
</script>
